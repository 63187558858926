export default [
  {
    path: '/login',
    name: 'auth-login',
    redirect: 'apply',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      public: true,
    },
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('@/views/admission/apply/index.vue'),
    meta: {
      layout: 'admission',
      resource: 'Auth',
      action: 'read',
      public: true,
      admission: true,
    },
    beforeEnter: (to, from, next) => {
      // Check if `type` param is missing
      if (!to.params.type) {
        // Redirect to `apply-form` with default param `type: 'cic'`
        next({ name: 'apply-form', params: { type: 'admission' } })
      } else {
        next() // Continue to the route if `type` is provided
      }
    },
    children: [
      // {
      //   path: '',
      //   name: 'apply-home',
      //   component: () => import('@/views/admission/apply/components/apply'),
      //   meta: {
      //     layout: 'admission',
      //     resource: 'Auth',
      //     action: 'read',
      //     public: true,
      //     admission: true,
      //   },
      // },
      // {
      //   path: ':type',
      //   name: 'apply-types',
      //   component: () => import('@/views/admission/apply/components/types.vue'),
      //   meta: {
      //     layout: 'admission',
      //     resource: 'Auth',
      //     action: 'read',
      //     public: true,
      //     admission: true,
      //   },
      // },
      {
        path: ':type?/:id?',
        name: 'apply-form',
        component: () => import('@/views/admission/apply/form.vue'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
        children: [
          {
            path: ':id/:success',
            name: 'apply-success',
            component: () => import('@/views/admission/apply/components/success.vue'),
            meta: {
              layout: 'admission',
              resource: 'Auth',
              action: 'read',
              public: true,
              admission: true,
            },
          },
        ],
      },
    ],
  },

  {
    path: '/apply-staff',
    name: 'apply-staff',
    component: () => import('@/views/admission/apply'),
    meta: {
      layout: 'admission',
      resource: 'Auth',
      action: 'read',
      public: true,
      admission: true,
    },
    redirect: { name: 'apply-staff-home' },
    children: [
      {
        path: '',
        name: 'apply-staff-home',
        component: () => import('@/views/admission/apply/components/apply'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
      },
      {
        path: ':type',
        name: 'apply-staff-types',
        component: () => import('@/views/admission/apply/components/types.vue'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
      },
      {
        path: ':type/:id',
        name: 'apply-staff-form',
        component: () => import('@/views/admission/apply/form.vue'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
        children: [
          {
            path: ':success',
            name: 'apply-staff-success',
            component: () => import('@/views/admission/apply/components/success.vue'),
            meta: {
              layout: 'admission',
              resource: 'Auth',
              action: 'read',
              public: true,
              admission: true,
            },
          },
        ],
      },
    ],
  },

  {
    path: '/applyForm/success/:type/:msg',
    name: 'applyForm-success',
    permission: 'public',
    component: () => import('@/views/admission/applicant_information/components/success.vue'),
    meta: {
    },
  },
]
